const COMPONENT_MAPPING = [
  // {
  //   key: 'toast',
  //   label: 'Toast',
  //   component: ,
  // },
  // {
  //   key: 'banner',
  //   label: 'Banner',
  //   component: ,
  // },
];

export default COMPONENT_MAPPING;
