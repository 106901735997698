import GetStarted from '../components/GetStarted';

const TOP_NAVIGATION = [

  {
    key: 'get_started',
    label: 'Get Started',
    component: GetStarted,
  },
];

export default TOP_NAVIGATION;
